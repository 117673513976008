<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Sales Price Detail</h4>
    </span>
  </div>
</template>

<script>
export default {
  name: "Detail",
};
</script>
