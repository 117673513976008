<template>
  <vx-card title="Sales Price">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>No</vs-th>
            <!-- <vs-th>Detail</vs-th> -->
            <vs-th>Approve / Reject</vs-th>
            <vs-th>Code</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Valid From - To</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ table.row_number_start + indextr }}</vs-td>
              <!-- <vs-td>
                <div class="flex gap-2">
                  <vs-button
                    color="success"
                    size="small"
                    @click.stop="handleEdit(tr, indextr)"
                    icon-pack="feather"
                    type="filled"
                    icon="icon-eye"
                  />
                </div>
              </vs-td> -->
              <vs-td>
                <div class="flex gap-2">
                  <vs-button
                    color="primary"
                    @click="doUpdateApprovalStatus(tr.id, 'approved')"
                    size="small"
                  >
                    Approve
                  </vs-button>
                  <vs-button
                    color="danger"
                    @click="doUpdateApprovalStatus(tr.id, 'rejected')"
                    size="small"
                  >
                    Reject
                  </vs-button>
                </div>
              </vs-td>
              <vs-td>{{ tr.code }}</vs-td>
              <vs-td>{{ tr.name }}</vs-td>
              <vs-td>{{ tr.valid_from_to }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>

      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <detail @close="handleClose" />
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>

<script>
import Detail from "./detail.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";

const PricingManagementV3Repository = RepositoryFactory.get(
  "pricingManagementV3"
);

export default {
  name: "SalesPrice",
  components: {
    Detail,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "desc",
        sort: "id",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        row_number_start: 1,
        row_number_end: 0,
      },
    };
  },
  methods: {
    async doUpdateApprovalStatus(id, status) {
      this.$vs.loading();
      try {
        const resp = await PricingManagementV3Repository.doUpdateApprovalStatus(
          id,
          status
        );
        if (resp.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: resp.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check-circle",
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while change status",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.getData();
      this.$vs.loading.close();
    },
    handleEdit(data, index) {
      this.detail = true;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pricing-management-v3/approval/list", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.table.row_number_start = resp.data.row_number_start;
            this.table.row_number_end = resp.data.row_number_end;

            this.setStartEnd();
          }

          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
